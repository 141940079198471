import React from "react";
import { graphql } from "gatsby";

// JS logic
import { getLocale } from "~utils";

// Components
import Layout from "~layout";
import SectionIconComponent from "~components/services/sectionIcon";
import ServiceComponent from "~components/services/service";
import PeekComponent from "~components/common/peek";

const ServicesPage = ({ data, location }) => {
  const {
    locale: localeKey,
    metadata,
    headerOverride,
    services,
    peek,
  } = data.markdownRemark.frontmatter;
  const locale = getLocale(localeKey);

  const split = 4;
  const servicesAbove = services.slice(0, split);
  const servicesBelow = services.slice(split);

  return (
    <Layout
      location={location}
      locale={locale}
      pageMetadata={metadata}
      pageHeader={headerOverride}
      hideScrollText={true}
    >
      <div className="stf-service-icons__wrapper">
        <div className="stf-container">
          <div className="stf-service-icons">
            {services.map((service, index) => (
              <SectionIconComponent
                key={index}
                data={service}
                location={location}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="stf-services__wrapper">
        <div className="stf-services">
          <div className="stf-container">
            {servicesAbove.map((serviceItem, index) => (
              <ServiceComponent data={serviceItem} key={index} />
            ))}
          </div>
        </div>

        <PeekComponent data={peek} location={location} locale={locale} />

        <div className="stf-services">
          <div className="stf-container">
            {servicesBelow.map((serviceItem, index) => (
              <ServiceComponent data={serviceItem} key={index} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPage($locale: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "services-page" }
        locale: { eq: $locale }
      }
    ) {
      frontmatter {
        locale
        metadata {
          title
          description
        }
        headerOverride {
          img {
            name
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          text
        }
        peek {
          sectionName
          title
          text
          img {
            name
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          button {
            text
            path
          }
          textUnder
        }
        services {
          id
          title
          text
          button {
            text
            dialog
          }
          img {
            name
            childImageSharp {
              fluid(maxWidth: 580, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          icon {
            name
            publicURL
          }
        }
      }
    }
  }
`;
