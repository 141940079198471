import React, { useState } from "react";

import DialogComponent from "~layout/dialog";
import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";

const ServiceComponent = ({ data }) => {
  const [serviceDialogState, serviceDialogToggle] = useState();
  const serviceDialogInstance = {
    id: `${data.id}-dialog`,
    title: data.title,
    status: serviceDialogState,
  };

  return (
    <article className="stf-service" id={data.id}>
      <div className="columns is-variable is-6">
        <div className="column is-half">
          <div className="stf-service__visual">
            <RenderImage
              image={data.img}
              modifiers={{
                className: "stf-service__visual__background",
              }}
            />
            <div className="stf-service__visual__icon">
              <RenderImage image={data.icon} />
            </div>
          </div>
        </div>

        <div className="column is-half">
          <div className="stf-service__content">
            <h2>{data.title}</h2>
            <p>{data.text}</p>
            <button
              onClick={(e) => {
                serviceDialogToggle(true);
                e.target.blur();
              }}
            >
              {data.button.text}
            </button>
          </div>
        </div>
      </div>

      <DialogComponent
        data={serviceDialogInstance}
        dialogToggle={serviceDialogToggle}
      >
        <RenderMD content={data.button.dialog} />
      </DialogComponent>
    </article>
  );
};
export default ServiceComponent;
