import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import RenderImage from "~wrappers/renderImage";

const SectionIconComponent = ({ data, location }) => {
  return (
    <div className="stf-service-icon">
      <AnchorLink
        to={`${location.pathname}#${data.id}`}
        stripHash
        className="stf-service-icon__visual"
      >
        <RenderImage
          image={data.icon}
          modifiers={{
            className: "stf-service-icon__visual__icon",
          }}
        />
      </AnchorLink>
      <p>{data.title}</p>
    </div>
  );
};
export default SectionIconComponent;
